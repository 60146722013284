import React, { useState } from "react";
import { Link, graphql } from "gatsby";

import LayoutMain from "../components/layout-main";
import SEO from "../components/seo";

const WeddingPhotos = ({ data, location, pageContext: { apiPosts } }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;

    return (
      <LayoutMain location={location} title={siteTitle}>
        <SEO title="Wedding Photo Gallary" />
        <p>
            It'll be here Soon...
        </p>
      </LayoutMain>
    );

};

export default WeddingPhotos;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
